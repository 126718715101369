import {
  Box,
  Skeleton,
  Tooltip,
  Pagination,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  styled,
} from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { useEffect } from "react";
import { ReactComponent as BrandLogo } from "../../assets/images/logo.svg";
import roleIcon from "../../assets/images/icons/role.svg";
import situationIcon from "../../assets/images/icons/situation.svg";
import lineIcon from "../../assets/images/icons/lines.svg";
import feedback from "../../assets/images/icons/feedback.svg";
import logout from "../../assets/images/icons/logout.svg";
import privacy from "../../assets/images/icons/privacy.svg";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { signOut } from "../../services/api-service";
import { useSearchParams, NavLink } from "react-router-dom";
import {
  getEvent,
  getKPIs,
  setSelectedUpdateTreeId,
  getTreeHistory,
} from "../../reducers/treeMapSlice";
import { getAnalytics, logEvent } from "firebase/analytics";
import "./SideBar.css";

const SideBar = ({ setShowManageEvent, setIsKPIView }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const analytics = getAnalytics();
  let [searchParams, setSearchParams] = useSearchParams();
  const eventHistory = useSelector((state) => state.treeMap.history);
  const eventLoading = useSelector((state) => state.treeMap.loading);
  const eventGenerating = useSelector((state) => state.treeMap.eventGenerating);
  const userData = useSelector((state) => state.treeMap.userData);
  const selectedTreeId = useSelector((state) => state.treeMap.selectedTreeId);
  const selectedUpdateTreeId = useSelector(
    (state) => state.treeMap.selectedUpdateTreeId
  );
  const totalPages = useSelector((state) => state.treeMap.totalPages);
  const isEventHistoryLoading = useSelector(
    (state) => state.treeMap.isTreeHistoryLoading
  );
  const kpis = useSelector((state) => state.treeMap.KPIs);
  const eventId = searchParams.get("eventId"),
    pageNumber = parseInt(searchParams.get("page")) || 1;

  const AppAccordion = styled((props) => (
    <Accordion
      disableGutters
      elevation={0}
      {...props}
      defaultExpanded={props.id === selectedTreeId}
    />
  ))(() => ({
    margin: "0px",
    marginTop: "2px",
    color: "#6e6e80",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:first-of-type": {
      marginTop: 0,
    },
    borderRadius: "7px",
    "&::before": {
      display: "none",
    },
    backgroundColor: "#f7f7f7",
  }));

  const AppAccordionSummary = styled((props) => (
    <AccordionSummary
      {...props}
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    />
  ))(({ theme, id }) => ({
    borderRadius: "7px",
    display: "flex",
    alignItems: "center",
    backgroundColor: id === selectedTreeId && "#ececf1",
    color: id === selectedTreeId && "#000",
    fontFamily: id === selectedTreeId && "ClashDisplay-Medium",
    padding: "5px 12px 5px 6px",
    minHeight: "35px",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      margin: "0px",
    },
    "&:hover": {
      backgroundColor: "#ececf1",
      color: "#000",
    },
  }));

  const AppAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    display: "flex",
    marginLeft: "18px",
    padding: "0px",
    paddingTop: "0px",
  }));

  const selectEvent = (key) => {
    setShowManageEvent(null);
    if (selectedUpdateTreeId) {
      dispatch(setSelectedUpdateTreeId(""));
    }
    if (key !== selectedTreeId && !eventGenerating) {
      logEvent(analytics, `Event Clicked From Event History!`, {
        user: userData._id,
      });
      dispatch(getEvent(key));
      setSearchParams({
        ...Object.fromEntries(searchParams),
        eventId: key,
      });
    }
  };

  useEffect(() => {
    dispatch(getTreeHistory(pageNumber));
  }, [pageNumber]);

  useEffect(() => {
    if (!eventLoading && selectedTreeId) {
      if (searchParams.has("code")) {
        const state = JSON.parse(searchParams.get("state"));
        setSearchParams({
          ...Object.fromEntries(searchParams),
          eventId: state.eventId,
          page: state.page,
        });
        selectEvent(state.eventId);
      } else {
        if (eventHistory.length > 0) {
          selectEvent(selectedTreeId);
        }
      }
    } else if (eventHistory.length > 0 && !eventId) {
      selectEvent(eventHistory[0]?._id);
    }
  }, [eventHistory, selectedTreeId, eventLoading]);

  const LoadingCard = ({ index }) => (
    <Box key={index} className="loadingCardContainer">
      <Skeleton
        variant="text"
        width="90%"
        height="20px"
        sx={{ backgroundColor: "#e7e7e7", mt: "10px" }}
        animation="wave"
      />
      <Skeleton
        variant="rectangular"
        width="90%"
        height="30px"
        sx={{ backgroundColor: "#e7e7e7", mt: 1 }}
        animation="wave"
      />
    </Box>
  );

  const EventCard = ({ event, i }) => {
    return (
      <AppAccordion key={i} id={event._id}>
        <AppAccordionSummary
          aria-controls="panel1a-content"
          id={event._id}
          onClick={() => {
            selectEvent(event._id);
            setIsKPIView(false);
          }}
        >
          <img
            src={event.requestType === 1 ? roleIcon : situationIcon}
            alt="Logo"
            width={event.requestType === 1 ? "24" : "16"}
            height={event.requestType === 1 ? "24" : "16"}
            style={{
              cursor: "pointer",
              marginRight: event.requestType === 1 ? "0px" : "4px",
              marginLeft: event.requestType === 1 ? "0px" : "4px",
            }}
          />
          <Box className="card-detail">
            <Tooltip
              enterDelay={1000}
              enterNextDelay={1000}
              title={
                event?.requestType == 1 ? event.employee_name : event.event_name
              }
              arrow
            >
              <Box>
                {event?.requestType == 1
                  ? event.employee_name
                  : event.event_name}
              </Box>
            </Tooltip>
          </Box>
        </AppAccordionSummary>
        <AppAccordionDetails>
          <img
            src={lineIcon}
            style={{ marginTop: "-5px", marginLeft: "2px" }}
            alt="Line"
          />
          <Box className="event-type-container">
            <Box
              className="event-type-title"
              mt="5px"
              onClick={(e) => {
                if (!eventGenerating) {
                  e.stopPropagation();
                  kpis?.eventId !== event._id && dispatch(getKPIs(event._id));
                  selectEvent(event._id);
                  setIsKPIView(true);
                }
              }}
            >
              KPIs
            </Box>
            <Box
              className="event-type-title"
              mt="5px"
              onClick={() => {
                selectEvent(event._id);
                setIsKPIView(false);
              }}
            >
              Tree
            </Box>
          </Box>
        </AppAccordionDetails>
      </AppAccordion>
    );
  };

  return (
    <Box className="sidebar">
      <BrandLogo
        style={{
          alignSelf: "center",
          width: 120,
          height: 27,
          margin: "36.5px 0px",
        }}
      />
      <Box
        className="add-tree"
        onClick={() => navigate("/create-event", { state: { from: "header" } })}
      >
        <Box className="new-title">Add New Event</Box>
        <AddCircleIcon
          color="primary"
          style={{ marginLeft: "5px" }}
        ></AddCircleIcon>
      </Box>
      <Box
        className="sidebar-inner"
        sx={{
          height: `calc(100% - ${totalPages > 1 ? "160px" : "120px"})`,
        }}
      >
        {isEventHistoryLoading ? (
          Array(4)
            .fill()
            .map((_, index) => <LoadingCard key={index} index={index} />)
        ) : eventHistory?.length ? (
          eventHistory?.map((event, i) => (
            <EventCard key={i} event={event} i={i} />
          ))
        ) : (
          <Box className="event-sidebar-sub-title">No Results</Box>
        )}
      </Box>
      {totalPages > 1 && (
        <Pagination
          sx={{
            alignSelf: "center",
            marginTop: "10px",
          }}
          page={pageNumber || 1}
          size="small"
          shape="rounded"
          count={totalPages}
          color="primary"
          onChange={(e, val) =>
            setSearchParams({ ...Object.fromEntries(searchParams), page: val })
          }
        />
      )}
      <Box className="sidebar-buttons-container">
        <NavLink
          to="https://www.antethink.com/privacy-policy"
          target="_blank"
          className="sidebar-button"
        >
          <img src={privacy} alt="privacy" />
          Privacy
        </NavLink>
        <NavLink
          to="https://antethink.nolt.io/newest"
          target="_blank"
          className="sidebar-button"
        >
          <img src={feedback} alt="feedback" />
          Feedback
        </NavLink>
        <Box className="sidebar-button" onClick={() => signOut()}>
          <img src={logout} alt="logout" />
          Logout
        </Box>
      </Box>
    </Box>
  );
};

export default SideBar;
