import React, { useState, useRef, useEffect } from "react";
import {
  generatePlan,
  // rateAndNote,
  // editNodeInfo,
  deleteBranch,
  updateNodePriority,
  getPlan,
  setPlanData,
  setPlanGenerating,
  setPlanLoading,
  setSelectedTreePlannedNodeNames,
  updateNodeName,
} from "../../reducers/treeMapSlice";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  Box,
  // Rating,
  // Tooltip,
  ClickAwayListener,
  Popper,
  Paper,
  Grow,
  TextField,
  Button,
} from "@mui/material";
import "./IndentedList.css";
// import {
//   createAssessment,
//   deleteAssessment,
//   editAssessment,
// } from "../../services/api-service";
import { ReactComponent as BinIcon } from "../../assets/images/icons/deleteIcon.svg";
import { ReactComponent as EditIcon } from "../../assets/images/icons/edit.svg";
import { ReactComponent as ImportantIcon } from "../../assets/images/icons/important.svg";
import { isSafariBrowser } from "../../utils/constants";
import { getSocket } from "../../socket";

// const searchParams = new URLSearchParams(window.location.search);
// const eventID = searchParams?.get("eventID");
// const label = searchParams?.get("label");

export const ChildlessRow = ({
  planGenerating,
  isAssesmentLink,
  isGuideTree,
  setImportantNotesChanged,
  rowData,
  // rowExtraInfo,
  // originalEventExtraInfo,
  rowCollapsed,
  userData,
  selectedTreeNode,
  selectedTreePlannedNodeNames,
  // handleEventRatings,
  dispatch,
  setSidebarOpened,
}) => {
  const rowRef = useRef(null);
  const [editOpened, setEditOpened] = useState(false);
  const [newNodeName, setNewNodeName] = useState(rowData.name);
  // const [ratingVal, setRatingVal] = useState();
  const [rowHovered, setRowHovered] = useState(false);
  // const [hover, setHover] = useState(-1);
  const [rowHeight, setRowHeight] = useState();
  const [UserData, setUserData] = useState(userData);
  const analytics = getAnalytics();
  const plannedRow = selectedTreePlannedNodeNames?.includes(rowData.name);
  const editIconRef = useRef(null);
  const socket = getSocket(userData?._id);
  const planResponseData = useRef(null);

  const noteButtonStyles = {
    border: "1px solid #bbbbbb",
    backgroundColor: "#f3f3f3",
    textTransform: "capitalize",
    padding: "0px 6px",
    fontFamily: "ClashDisplay-Regular",
    fontSize: "13px",
    height: "30px",
    "&:hover": {
      color: "#1D77B7 !important",
      border: "1px solid #1D77B7 !important",
    },
  };

  const Spakles = (isPlan, selected) => (
    <svg
      width={isPlan ? "12" : "14"}
      height={isPlan ? "12" : "14"}
      viewBox="0 0 19 18"
      fill={selected ? "#1D77B7" : "none"}
      style={{
        marginLeft: 4,
        marginTop: 3,
        transition: "opacity 1.25s ease-in-out",
      }}
    >
      <path
        d="M11.7671 0.680054L13.3533 4.96676L17.6401 6.55299L13.3533 8.13921L11.7671 12.4259L10.1809 8.13921L5.89414 6.55299L10.1809 4.96676L11.7671 0.680054Z"
        stroke={selected ? "#1D77B7" : "#585858"}
        strokeWidth={1.3}
        strokeLinejoin="round"
      />
      <path
        d="M4.4259 10.4683L5.81155 12.5085L7.85179 13.8942L5.81155 15.2798L4.4259 17.32L3.04024 15.2798L1 13.8942L3.04024 12.5085L4.4259 10.4683Z"
        stroke={selected ? "#1D77B7" : "#585858"}
        strokeWidth={1.3}
        strokeLinejoin="round"
      />
    </svg>
  );

  // const labels = {
  //   1: "Totally Disagree",
  //   2: "Disagree",
  //   3: "Neutral",
  //   4: "Agree",
  //   5: "Totally Agree",
  // };

  // const Circle = ({ color, no }) => {
  //   return (
  //     <svg width="18" height="18">
  //       <circle
  //         cx="10"
  //         cy="10"
  //         r="6.5"
  //         stroke={
  //           originalEventRating?.rate == no
  //             ? "green"
  //             : hover == no || (ratingVal == no && hover === -1)
  //               ? color
  //               : "#ababab"
  //         }
  //         strokeWidth={originalEventRating?.rate == no ? 1.5 : 1}
  //         fill={
  //           hover == no || (ratingVal == no && hover === -1) ? color : "none"
  //         }
  //       />
  //     </svg>
  //   );
  // };

  // const customIcons = {
  //   1: <Circle color="#FF5733" no="1" />,
  //   2: <Circle color="#FFC0CB" no="2" />,
  //   3: <Circle color="#dadada" no="3" />,
  //   4: <Circle color="#ADD8E6" no="4" />,
  //   5: <Circle color="#337DFF" no="5" />,
  // };

  // const IconContainer = (props) => {
  //   const { value, ...other } = props;
  //   return <span {...other}>{customIcons[value]}</span>;
  // };

  // const handleRatingChange = (e, newValue) => {
  //   setRatingVal(newValue);
  //   if (newValue == null) {
  //     isAssesmentLink
  //       ? deleteAssessment({
  //           eventID,
  //           nodeRateId: rowExtraInfo._id,
  //         }).then(
  //           (res) =>
  //             res?.status === 200 && handleEventRatings("delete", rowExtraInfo)
  //         )
  //       : dispatch(
  //           editNodeInfo({
  //             note: note,
  //             nodeId: rowExtraInfo._id,
  //             rate: 0,
  //           })
  //         );
  //   } else if (ratingVal >= 0 && newValue != null) {
  //     isAssesmentLink
  //       ? editAssessment({
  //           eventID,
  //           nodeRateId: rowExtraInfo._id,
  //           rate: newValue,
  //           label,
  //         }).then(
  //           (res) =>
  //             res?.status === 200 &&
  //             handleEventRatings("edit", { ...rowExtraInfo, rate: newValue })
  //         )
  //       : dispatch(
  //           editNodeInfo({
  //             note: note,
  //             nodeId: rowExtraInfo._id,
  //             rate: newValue,
  //           })
  //         );
  //   } else {
  //     isAssesmentLink
  //       ? createAssessment({
  //           label,
  //           node_name: rowData.name,
  //           eventId: eventID,
  //           rate: newValue,
  //         }).then(
  //           (res) =>
  //             res?.status === 201 &&
  //             handleEventRatings("create", res?.data?.result)
  //         )
  //       : dispatch(
  //           rateAndNote({ note: note, node_name: rowData.name, rate: newValue })
  //         );
  //   }
  // };

  const handleUpdateNodeName = () => {
    dispatch(updateNodeName({ nodeName: rowData.name, newNodeName }));
    handleEditClickAway();
  };

  const handleEditClickAway = () => {
    setNewNodeName(rowData.name);
    setEditOpened(false);
    setRowHovered(false);
  };

  const onConnect_GeneratePlan = () => {
    dispatch(
      generatePlan(
        rowData.name,
        onStreamCompleted_GeneratePlan,
        setSidebarOpened
      )
    );
  };

  const onStreamStarted_GeneratePlan = () => {
    dispatch(setPlanGenerating(true));
    dispatch(
      setSelectedTreePlannedNodeNames([
        ...selectedTreePlannedNodeNames,
        rowData.name,
      ])
    );
    dispatch(setPlanLoading(false));
  };

  const onReciveStreamChunk_GeneratePlan = (chunk) => {
    planResponseData.current += chunk;
    dispatch(setPlanData(planResponseData.current));
  };

  const onStreamCompleted_GeneratePlan = () => {
    dispatch(setPlanGenerating(false));
    socket.off("connect", onConnect_GeneratePlan);
    socket.off("streamChunk", onReciveStreamChunk_GeneratePlan);
    socket.off("streamCompleted", onStreamCompleted_GeneratePlan);
    socket.disconnect();
  };

  const handleNodeClick = () => {
    if (!planGenerating && !socket.connected) {
      logEvent(analytics, `Plan it Clicked!`, {
        user: UserData._id,
      });
      setSidebarOpened(true);
      if (selectedTreePlannedNodeNames.includes(rowData.name)) {
        dispatch(getPlan(rowData.name));
      } else {
        socket.on("connect", onConnect_GeneratePlan);
        socket.on("streamStarted", onStreamStarted_GeneratePlan);
        socket.on("streamChunk", onReciveStreamChunk_GeneratePlan);
        socket.on("streamCompleted", onStreamCompleted_GeneratePlan);
        socket.connect();
      }
    }
  };

  useEffect(() => {
    setRowHeight(
      document.getElementById(rowData.name)?.getBoundingClientRect().height
    );
  }, []);

  useEffect(() => {
    setUserData(userData);
    setSelectedTreePlannedNodeNames(selectedTreePlannedNodeNames);
  }, [userData, selectedTreePlannedNodeNames]);

  useEffect(() => {
    // setRatingVal(rowExtraInfo?.rate);
    // setNote(rowData?.comment);
  }, [rowData]);

  return (
    <div
      className={`childlessRow ${rowCollapsed ? "collapsed" : "expanded"}`}
      ref={rowRef}
      style={{
        width: "calc(100% - 60px)",
        height: rowCollapsed ? "0px" : rowHeight ?? "auto",
        opacity: 1,
      }}
      onMouseEnter={() => setRowHovered(true)}
      onMouseLeave={() => setRowHovered(false)}
      key={rowData.name}
      id={rowData.name}
    >
      <span
        onClick={() =>
          isAssesmentLink || isGuideTree ? null : handleNodeClick(rowData)
        }
        style={{
          paddingTop: isSafariBrowser ? 2.5 : 1,
          paddingRight: 5,
          paddingBottom: 2.5,
          cursor: "pointer",
          maxWidth: `calc(100% - ${plannedRow ? "230px" : "270px"})`,
        }}
      >
        {rowData.name}
      </span>
      {/* {!isGuideTree && (ratingVal || rowHovered || originalEventRating) && (
        <div className="rating" onClick={(e) => e.stopPropagation()}>
          <Rating
            size="small"
            defaultValue={0}
            value={ratingVal}
            onChange={handleRatingChange}
            onChangeActive={(e, newHover) => {
              setHover(newHover);
            }}
            IconContainerComponent={IconContainer}
            highlightSelectedOnly
          />
          {hover !== -1 && (
            <div
              onMouseEnter={() => setRowHovered(false)}
              style={{
                position: "absolute",
                fontSize: 12,
                marginTop: -3,
                cursor: "default",
              }}
            >
              {labels[hover]}
            </div>
          )}
        </div>
      )} */}
      {!isAssesmentLink && !isGuideTree && (plannedRow || rowHovered) && (
        <div style={{ display: "flex" }}>
          <span
            className={plannedRow ? "" : "plan"}
            style={{ cursor: "pointer", marginTop: "0.2px" }}
            onClick={(e) => (e.stopPropagation(), handleNodeClick(rowData))}
          >
            {!plannedRow && "Plan"}
            {plannedRow
              ? Spakles("", selectedTreeNode === rowData.name)
              : Spakles("plan", false)}
          </span>
        </div>
      )}
      {!isAssesmentLink &&
        !isGuideTree &&
        !rowCollapsed &&
        (rowHovered || editOpened) && (
          <EditIcon
            className="editIcon"
            onClick={() => setEditOpened(true)}
            ref={editIconRef}
          />
        )}
      {editOpened && (
        <Popper
          open={editOpened}
          anchorEl={editIconRef.current}
          placement="bottom-start"
          transition
          sx={{ zIndex: 1, maxWidth: "370px" }}
          disablePortal
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={handleEditClickAway}>
                  <Box
                    className="dropdown-content"
                    sx={{ cursor: "default", padding: 1, width: "350px" }}
                  >
                    <TextField
                      InputProps={{
                        style: {
                          width: "100%",
                          padding: "5px",
                          fontSize: "14px",
                        },
                      }}
                      fullWidth
                      autoFocus
                      maxRows={10}
                      multiline={true}
                      onChange={(e) => setNewNodeName(e.target.value)}
                      value={newNodeName}
                    />
                    <Box
                      sx={{ display: "flex", mt: "8px", justifyContent: "end" }}
                    >
                      <Button
                        onClick={handleEditClickAway}
                        variant="outline"
                        sx={{ ...noteButtonStyles, mr: 1 }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="outline"
                        sx={noteButtonStyles}
                        onClick={handleUpdateNodeName}
                      >
                        Update
                      </Button>
                    </Box>
                  </Box>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
      {(rowData.isMarked || (!isAssesmentLink && rowHovered)) && (
        <ImportantIcon
          className="importantIcon"
          onClick={() => {
            dispatch(updateNodePriority(rowData.name));
            isGuideTree && setImportantNotesChanged(true);
          }}
        />
      )}
      {!isAssesmentLink && rowHovered && (
        <BinIcon
          className="deleteIcon"
          onClick={() => dispatch(deleteBranch(rowData.name))}
        />
      )}
    </div>
  );
};
