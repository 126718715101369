import { Box, Button, Card, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "../../assets/images/icons/copy.svg";
import editIcon from "../../assets/images/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/deleteIcon.svg";
import {
  setIsConfirmDeleteModalOpen,
  setSelectedTreeNode,
  updatePlan,
  notify,
  setPlanData,
  setPlanGenerating,
} from "../../reducers/treeMapSlice";
import Loader from "../Loader/Loader";
import Sparkles from "../../assets/images/icons/sparkles.svg";
import "./Plans.css";
import { IsMobile } from "../../utils/deviceType";
import { useState, useRef } from "react";
import TextEntryModal from "../TextEntryModal/TextEntryModal";
import { getSocket } from "../../socket";

export default function Plans({ setSidebarOpened }) {
  const [editOpen, setEditOpen] = useState(false);
  const dispatch = useDispatch();
  const isMobile = IsMobile();
  const bottomEl = useRef(null);
  const planData = useSelector((state) => state.treeMap.planData);
  const plansLoading = useSelector((state) => state.treeMap.plansLoading);
  const userData = useSelector((state) => state.treeMap.userData);
  const planGenerating = useSelector((state) => state.treeMap.planGenerating);
  const selectedTreeId = useSelector((state) => state.treeMap.selectedTreeId);
  const selectedTreeNode = useSelector(
    (state) => state.treeMap.selectedTreeNode
  );
  const selectedTreePlannedNodeNames = useSelector(
    (state) => state.treeMap.selectedTreePlannedNodeNames
  );
  const socket = getSocket(userData?._id);
  const additionalContext = useRef(null);
  const planResponseData = useRef(null);

  const copyFunction = () => {
    navigator.clipboard.writeText(
      document.getElementsByClassName("card")[0].innerText
    );
    notify("Plan data copied to clipboard", "success");
  };

  const toggleOpenConfirmationModal = () => {
    dispatch(setIsConfirmDeleteModalOpen({ type: "plan" }));
  };

  const renderPreloadedPlan = () => {
    return (
      <Box>
        <Box className="plan-key">Action Plan:</Box>
        {planData.action_plan.map((item, index) => (
          <Box key={index} className="plan-key-data">
            <b>{index + 1}</b> - {item}
          </Box>
        ))}
        <Box className="plan-key">Out-of-the-Box Ideas:</Box>
        {planData.out_of_the_box_ideas.map((item, index) => (
          <Box key={index} className="plan-key-data">
            <b>{index + 1}</b> - {item}
          </Box>
        ))}
      </Box>
    );
  };

  const generateStream = () => {
    const type1 = planData.indexOf("**Action Plan"),
      type2 = planData.indexOf("Action Plan:"),
      actionPlanIndex = planData.search(/"?(action[- ]plan)"?\s*:/i),
      outOfBoxIndex = planData.search(
        /"?(out[- ]of[- ]the[- ]box[- ]ideas)"?\s*:/i
      ),
      splitCharacter = (type1 > 0 || type2) > 0 ? /\d+\./ : '",';

    if (actionPlanIndex > 0) {
      const renderSection = (title, statement, startIndex, endIndex) => {
        const section = statement
          .substring(startIndex, endIndex)
          .split(splitCharacter);
        return (
          <Box>
            <Box className="plan-key">{title}</Box>
            {section.map((el, index) => {
              const sentence = el.replace(/(\d+\.)|["#*\]`,}\[\]]/g, "");
              return (
                <Box key={index} className="plan-key-data">
                  <b>
                    {index + 1} -
                    {sentence.includes(":") &&
                      sentence.substring(0, sentence.indexOf(":"))}
                  </b>
                  {sentence.includes(":")
                    ? sentence.substring(sentence.indexOf(":"))
                    : sentence}
                </Box>
              );
            })}
          </Box>
        );
      };

      const renderOutOfTheBox = () => {
        if (outOfBoxIndex > 0) {
          const outOfTheBox = planData.substring(outOfBoxIndex + 15),
            startIndex = type1 !== -1 ? 12 : 10,
            endIndex =
              outOfTheBox.indexOf("```json") > 0
                ? outOfTheBox.indexOf("```json")
                : outOfTheBox.indexOf("]") > 0
                  ? outOfTheBox.indexOf("]")
                  : undefined;

          return renderSection(
            "Out-of-the-Box Ideas:",
            outOfTheBox,
            startIndex,
            endIndex
          );
        }
      };
      const renderPlanActions = () => {
        const startIndex = actionPlanIndex + (type1 !== -1 ? 16 : 14),
          endIndex = outOfBoxIndex > 0 ? outOfBoxIndex : undefined;

        return renderSection("Action Plan:", planData, startIndex, endIndex);
      };

      return (
        <Box ref={bottomEl}>
          {renderPlanActions()}
          {renderOutOfTheBox()}
        </Box>
      );
    } else return <Loader text={"Generating Action Plan..."} />;
  };

  const addContext = (context) => {
    additionalContext.current = context;
    socket.on("connect", onConnect_updatePlan);
    socket.on("streamChunk", onReciveStreamChunk_updatePlan);
    socket.on("streamCompleted", onStreamCompleted_updatePlan);
    socket.connect();
  };

  const onConnect_updatePlan = () => {
    dispatch(setPlanGenerating(true));
    dispatch(
      updatePlan(selectedTreeId, selectedTreeNode, additionalContext.current)
    );
    planResponseData.current = "";
  };

  const onReciveStreamChunk_updatePlan = (chunk) => {
    planResponseData.current += chunk;
    dispatch(setPlanData(planResponseData.current));
  };

  const onStreamCompleted_updatePlan = () => {
    dispatch(setPlanGenerating(false));
    socket.off("connect", onConnect_updatePlan);
    socket.off("streamChunk", onReciveStreamChunk_updatePlan);
    socket.off("streamCompleted", onStreamCompleted_updatePlan);
    socket.disconnect();
  };

  // useEffect(() => {
  //   bottomEl?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  // }, [planData]);

  return (
    <Box className="plans-panel">
      <Box className="plans-header">
        <IconButton
          onClick={() => (
            dispatch(setSelectedTreeNode(null)),
            isMobile && setSidebarOpened(false),
            setTimeout(
              () => {
                setSidebarOpened(false);
              },
              isMobile ? 500 : 0
            )
          )}
          color="primary"
        >
          <CloseIcon className="back-icon" fontSize="small" />
        </IconButton>
      </Box>
      <Box className="plan-title-box">
        <img
          src={Sparkles}
          alt="Sparkles"
          width={20}
          height={20}
          style={{ marginTop: "5px" }}
        />
        {selectedTreeNode ? (
          <Box className="plan-title">{selectedTreeNode}</Box>
        ) : null}
      </Box>
      {plansLoading ? (
        <Loader
          text={`${selectedTreePlannedNodeNames?.includes(selectedTreeNode) ? "Loading" : "Generating"} Action Plan...`}
        />
      ) : planData ? (
        <Box>
          <Card className="card">
            <Box className="plan-section">
              {planData?.hasOwnProperty("eventId")
                ? renderPreloadedPlan()
                : generateStream()}
            </Box>
          </Card>
          {!planGenerating && (
            <>
              <Button
                onClick={() => setEditOpen(true)}
                variant="outline"
                startIcon={<img alt="EditIcon" src={editIcon} />}
                className="end-button"
              >
                Context
              </Button>
              <Button
                onClick={copyFunction}
                variant="outline"
                startIcon={<img alt="CopyIcon" src={ContentCopyIcon} />}
                className="end-button"
              >
                Copy
              </Button>
              <Button
                variant="outline"
                startIcon={<DeleteIcon fill="#585858" />}
                className="end-button"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleOpenConfirmationModal();
                }}
              >
                Delete
              </Button>
            </>
          )}
        </Box>
      ) : (
        <Loader text={"Generating Action Plan..."} />
      )}
      <TextEntryModal
        header="Add Context"
        inputPlaceholder="Enter feedback to tune action plan"
        buttonText="Add Context"
        textEntryModalOpened={editOpen}
        closeTextEntryModal={() => setEditOpen(false)}
        action={addContext}
      />
    </Box>
  );
}
